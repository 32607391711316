import "@css/app.css";

document.addEventListener("DOMContentLoaded", function() {
  var sideMenuItems = document.getElementsByClassName('side-menu-item');
  
  for (let item of sideMenuItems) {
    item.addEventListener("mouseover", function() {
      var initImg = this.querySelector('.initial-img')
      var hiddenImg = this.querySelector('.hidden-img')

      initImg.classList.add("hidden");
      hiddenImg.classList.remove("hidden");

    })
    item.addEventListener("mouseout", function() {
      var initImg = this.querySelector('.initial-img')
      var hiddenImg = this.querySelector('.hidden-img')

      hiddenImg.classList.add("hidden");
      initImg.classList.remove("hidden");
    })
  }
});

